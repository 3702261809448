import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/layout';
import ContactForm from '../components/forms/contact';

export default function Contact() {
  return (
    <Layout title="Contact Us">
      <div className="w-full pt-32 md:pt-40 lg:pt-60">
        <ContactForm withH1 />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
